import React, { useEffect } from 'react';
import './App.css';
import { MapContainer, TileLayer, Popup, Marker, useMap } from 'react-leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Locations from './locations';
import DirectionsPng from '../src/directions.png';
import * as L from 'leaflet';
import ReactGA from 'react-ga';
import markerAdapost from './icon.png';
import ReportPng from './report.png';

// const ads = [
//   <>
//     <div className='button-coffee'>
//       <a
//         href='https://event.2performant.com/events/click?ad_type=banner&unique=4343f2b04&aff_code=f03b56c31&campaign_unique=a1880c1fe'
//         target='_blank'
//         rel='nofollow noreferrer'
//       >
//         <img
//           src='https://img.2performant.com/system/paperclip/banner_pictures/pics/189349/original/189349.jpg'
//           alt='fashiondays.ro'
//           title='fashiondays.ro'
//           border='0'
//           height='83px'
//           width='323px'
//         />
//       </a>
//     </div>
//   </>,
//   <>
//     <div className='button-coffee'>
//       <a
//         href='https://event.2performant.com/events/click?ad_type=banner&unique=73e24b15b&aff_code=f03b56c31&campaign_unique=c083fda82'
//         target='_blank'
//         rel='nofollow noreferrer'
//       >
//         <img
//           src='https://img.2performant.com/system/paperclip/banner_pictures/pics/186582/original/186582.png'
//           alt='orange.ro'
//           title='orange.ro'
//           border='0'
//           height='100px'
//           width='320px'
//         />
//       </a>
//     </div>
//   </>,
//   <>
//     <div className='button-coffee'>
//       <a
//         href='https://event.2performant.com/events/click?ad_type=banner&unique=a8d3ca7ec&aff_code=f03b56c31&campaign_unique=a5e9e1225'
//         target='_blank'
//         rel='nofollow noreferrer'
//       >
//         <img
//           src='https://img.2performant.com/system/paperclip/banner_pictures/pics/189047/original/189047.jpg'
//           alt='answear.ro '
//           title='answear.ro '
//           border='0'
//           height='50px'
//           width='320px'
//         />
//       </a>
//     </div>
//   </>,
//   <>
//     <div className='button-coffee'>
//       <a
//         href='https://event.2performant.com/events/click?ad_type=banner&unique=e38a8dd88&aff_code=f03b56c31&campaign_unique=9a6f02fef'
//         target='_blank'
//         rel='nofollow noreferrer'
//       >
//         <img
//           src='https://img.2performant.com/system/paperclip/banner_pictures/pics/189360/original/189360.jpg'
//           alt='libris.ro '
//           title='libris.ro '
//           border='0'
//           height='60px'
//           width='468px'
//         />
//       </a>
//     </div>
//   </>,
//   <>
//     <div className='button-coffee'>
//       <a
//         href='https://event.2performant.com/events/click?ad_type=banner&unique=0262a3370&aff_code=f03b56c31&campaign_unique=d4f678b43'
//         target='_blank'
//         rel='nofollow noreferrer'
//       >
//         <img
//           src='https://img.2performant.com/system/paperclip/banner_pictures/pics/188694/original/188694.jpg'
//           alt='evomag.ro '
//           title='evomag.ro '
//           border='0'
//           height='60px'
//           width='468px'
//         />
//       </a>
//     </div>
//   </>,
// ];

const iconMarker = L.icon({
  iconUrl: markerAdapost,
  iconSize: [30, 30],
  iconAnchor: [10, 20],
  popupAnchor: [5, -10],
});

const createClusterCustomIcon = function (cluster) {
  if (cluster.getChildCount() > 100) {
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: 'marker-cluster-custom',
      iconSize: L.point(50, 50, true),
    });
  } else if (cluster.getChildCount() > 30) {
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: 'marker-cluster-custom2',
      iconSize: L.point(40, 40, true),
    });
  } else {
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: 'marker-cluster-custom3',
      iconSize: L.point(30, 30, true),
    });
  }
};

ReactGA.initialize('UA-222256154-1');

function App() {
  const SearchField = () => {
    const provider = new OpenStreetMapProvider({
      params: {
        'accept-language': 'ro', // render results in Dutch
        countrycodes: 'ro', // limit search results to the Netherlands
        email: 'avadaniteodor@gmail.com', // auth for large number of requests
      },
    });

    // @ts-ignore
    const searchControl = new GeoSearchControl({
      provider: provider,
      style: 'bar',
      // marker: {
      //   icon: userIcon,
      // },
      searchLabel: '🔎 Cautare...',
    });

    const map = useMap();

    useEffect(() => {
      map.addControl(searchControl);
      return () => map.removeControl(searchControl);
    }, []);

    return null;
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className='container'>
      <div className='map'>
        {/* {ads[Math.floor(Math.random() * ads.length)]} */}
        <MapContainer
          className='markercluster-map'
          center={[45.765427, 24.823222]}
          zoom={7}
          maxZoom={18}
          zoomControl={false}
          preferCanvas={true}
        >
          <SearchField />
          <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <MarkerClusterGroup
            showCoverageOnHover={false}
            iconCreateFunction={createClusterCustomIcon}
          >
            {Locations.map((location) => (
              <Marker
                icon={iconMarker}
                key={location.nr}
                position={{
                  lat: Number(location.latitudine),
                  lng: Number(location.longitudine),
                }}
              >
                {' '}
                <Popup>
                  <a
                    className='report-problem'
                    href='mailto:adapostulmeu@gmail.com'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {' '}
                    <span data-text='Raportează o problemă' class='tooltip'>
                      <img
                        alt='raporteaza-problema'
                        src={ReportPng}
                        width={15}
                        height={15}
                      />
                    </span>
                  </a>

                  <div className='infoBox'>
                    <h3>Adăpostul nr. {location.nr} </h3>
                    <p>
                      <strong>Adresa: </strong>
                      {location.adresa}
                    </p>
                    <p>
                      <a
                        href={
                          'https://www.google.com/maps/search/?api=1&query=' +
                          location.latitudine +
                          ',' +
                          location.longitudine
                        }
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          alt='obtine-directii-buncar'
                          src={DirectionsPng}
                          width={15}
                          height={15}
                        />{' '}
                        Obține direcții
                      </a>
                    </p>
                    <p></p>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
        </MapContainer>
      </div>
    </div>
  );
}

export default App;
